import React from 'react'
import styles from './ProfilePicture.module.css'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import DialogContainer from '../../../components/dialogcontainer/DialogContainer';
import DynamicButton from '../../../components/dynamicbuton/DynamicButton';

interface ProfilePictureProps {
    isShow: boolean,
    driver_id: string,
    onClose: () => void
}

const ProfilePicture = ({ isShow, driver_id, onClose }: ProfilePictureProps) => {
    const image = "https://uracab.com/Pangkam.jpeg"
    return isShow ? (
        <DialogContainer>
            <div className={styles.section}>
                <div className={styles.heading}>
                    <h3>Profile Picture</h3>
                    <DynamicButton icon={faXmark} isHaveBackground={true} isRounded={true} onClick={onClose} />
                </div>
                <div className={styles.imageContainer}>
                    {
                        image ?
                            <img className={styles.image} src="https://uracab.com/Pangkam.jpeg" alt="" />
                            :
                            <small>no image found!</small>
                    }
                </div>
                <div className={styles.buttons}>
                    <DynamicButton label='Remove' isHaveBackground={false} isRounded={false} />
                    <DynamicButton label='Change' isHaveBackground={true} isRounded={true} />
                </div>
            </div>
        </DialogContainer>
    ) : null
}

export default ProfilePicture