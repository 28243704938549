import React from 'react'
import DialogContainer from '../../../../components/dialogcontainer/DialogContainer';
import styles from './DL.module.css'
import DynamicButton from '../../../../components/dynamicbuton/DynamicButton';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface DLProps {
    isShow: boolean,
    driver_id: string,
    onClose: () => void
}

const DL = ({ isShow, driver_id, onClose }: DLProps) => {
    return isShow ? (
        <DialogContainer>
            <div className={styles.section}>
                <div className={styles.heading}>
                    <h3>Driver License Detail</h3>
                    <DynamicButton icon={faXmark} isHaveBackground={true} isRounded={true} onClick={onClose} />
                </div>
            </div>
        </DialogContainer>
    ) : null
}

export default DL