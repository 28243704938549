import { faArrowRight, faCalendarDays, faChartPie, faChevronRight, faCreditCard, faCube, faGauge, faGear, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RiLogoutCircleLine } from "react-icons/ri";
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './Sidebar.module.css'

const sidelists = [
    { url: '/', name: 'Dashboard', icon: <FontAwesomeIcon className={styles.icon} icon={faGauge} /> },
    { url: '/rides', name: 'Rides', icon: <img className={`${styles.icon} ${styles.scaleIcon}`} src="/car_ride.png" /> },
    { url: '/drivers', name: 'Drivers', icon: <img className={`${styles.icon} ${styles.scaleIcon}`} src="/driver.png" /> },
    { url: '/users', name: 'Users', icon: <FontAwesomeIcon className={styles.icon} icon={faUserGroup} /> },
    { url: '/payments', name: 'Payments', icon: <FontAwesomeIcon className={styles.icon} icon={faCreditCard} /> },
    { url: '/executives', name: 'Executives', icon: <FontAwesomeIcon className={styles.icon} icon={faUsers} /> },
    { url: '/analytics', name: 'Analytic & Reports', icon: <FontAwesomeIcon className={styles.icon} icon={faChartPie} /> },
    { url: '/settings', name: 'System Settings', icon: <FontAwesomeIcon className={styles.icon} icon={faGear} /> },
]

function Sidebar() {
    const { pathname } = useLocation();

    const handleLogoutClick = ()=>{
        localStorage.removeItem('token');
        window.location.replace('/');
    }

    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                {sidelists.map(item => (
                    <li className={`${styles.item} ${pathname === item.url ? styles.active : ''}`}
                        key={item.name}>

                        <Link to={item.url} className={styles.link}>
                            {item.icon}{item.name}
                        </Link>
                    </li>
                ))}
            </ul>
            <div style={{ flex: 1 }}></div>
            <div className={styles.bottom}>
                <div>
                    <img src="/driver.png" alt="" />
                    <p><span>Pangkam D Sangma</span> <span>Joint Chief Technical Officer</span></p>
                </div>
                <button onClick={handleLogoutClick}>
                    <RiLogoutCircleLine />
                    <span>Logout</span>
                </button>
            </div>
        </div>

    )
}

export default Sidebar