import React, { useEffect, useState } from 'react'
import styles from './Drivers.module.css'
import { useSearchParams } from 'react-router-dom';
import DynamicTable from '../../components/dynamictable/DynamicTable';
import DynamicForm from '../../components/dynamicform/DynamicForm';
import StandardInput from '../../components/inputs/standardInput/StandardInput';
import SelectInput from '../../components/inputs/selectInput/SelectInput';
import Add from './add/Add';
import Aadhaar from './docs/aadhaar/Aadhaar';
import Pan from './docs/pan/Pan';
import DL from './docs/dl/DL';
import CarRC from './docs/rc/CarRC';
import ProfilePicture from './prodfilepicture/ProfilePicture';
import Bank from './docs/bank/Bank';


interface Column {
    label: string;
    name: string;
    hasBackground?: string;
    isClickable?: boolean
}

interface Row {
    id: string
    name: string;
    profile_picture: string;
    age: number;
    gender: string;
    address: string;
    dl: string;
    rc: string;
    pan: string;
    aadhaar: string;
    bank: string;
}

type DriverSelect = {
    id: string,
    column: string,
}

interface DriverSelected {
    id: string,
    column: string,
    onReset: () => void
}

function getItemById(array: any[], id: string) {
    return array.find(item => item.id == id);
}

function Drivers() {
    const [searchText, setSearchText] = useState<string>('');
    const [dates, setDates] = useState<{ startDate?: Date, endDate?: Date } | null>(null);
    const [page, setPage] = useState<number>(1);
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const [selectedDriver, setSelectedDriver] = useState<DriverSelect | null>(null);


    const columns: Column[] = [
        { label: "Name", name: "name", isClickable: true },
        { label: "Profile Picture", name: "profile_picture", isClickable: true },
        { label: "Age", name: "age" },
        { label: "Gender", name: "gender" },
        { label: "Address", name: "address" },
        { label: "Driving License", name: "dl", isClickable: true },
        { label: "Car", name: "rc", isClickable: true },
        { label: "PAN", name: "pan", isClickable: true },
        { label: "Aadhaar", name: "aadhaar", isClickable: true },
        { label: "Bank", name: "bank", isClickable: true },
    ];

    function getRandomId(): string {
        return Math.random().toString(36).substr(2, 9);
    }

    function getRandomName(): string {
        const names = ["John Doe", "Jane Smith", "Alice Johnson", "Bob Brown"];
        return names[Math.floor(Math.random() * names.length)];
    }

    function getRandomAge(): number {
        return Math.floor(Math.random() * 60) + 18;
    }

    function getRandomGender(): string {
        return Math.random() > 0.5 ? "Male" : "Female";
    }

    function getRandomAddress(): string {
        const streets = ["Main St", "Second St", "Third St", "Fourth St"];
        const cities = ["New York", "Los Angeles", "Chicago", "Houston"];
        return `${Math.floor(Math.random() * 1000)} ${streets[Math.floor(Math.random() * streets.length)]}, ${cities[Math.floor(Math.random() * cities.length)]}`;
    }

    function getRandomDrivingLicense(): string {
        return "NL" + Math.random().toString(36).substr(2, 8).toUpperCase();
    }

    function getRandomCar(): string {
        const cars = ["Toyota", "Ford", "Honda", "Chevrolet"];
        return cars[Math.floor(Math.random() * cars.length)];
    }

    function getRandomPAN(): string {
        return "PAN" + Math.random().toString(36).substr(2, 8).toUpperCase();
    }

    function getRandomAadhaar(): string {
        return Math.floor(Math.random() * 10000000000).toString(); // 12 digit number
    }

    function getRandomBank(): string {
        const banks = ["Bank of America", "Wells Fargo", "Chase", "Citibank"];
        return banks[Math.floor(Math.random() * banks.length)];
    }

    function generateRandomRows(numRows: number): Row[] {
        const rows: Row[] = [];
        for (let i = 0; i < numRows; i++) {
            const row: Row = {
                id: getRandomId(),
                profile_picture: "",
                name: getRandomName(),
                age: getRandomAge(),
                gender: getRandomGender(),
                address: getRandomAddress(),
                dl: getRandomDrivingLicense(),
                rc: getRandomCar(),
                pan: getRandomPAN(),
                aadhaar: getRandomAadhaar(),
                bank: getRandomBank(),
            };
            rows.push(row);
        }
        return rows;
    }

    const randomRows = generateRandomRows(20);



    return (
        <div className={styles.container}>
            <div className={styles.contents}>
                <DynamicTable
                    title={'Drivers'}
                    columns={columns}
                    rows={randomRows}
                    onItemClick={(e) => {
                        setSelectedDriver(e)
                    }}
                    page={{
                        page: page,
                        totalPage: 5,
                        onPageChange(page) {
                            setPage(page);
                        }
                    }}
                    search={{
                        text: searchText,
                        onSearchTextChange(text) {
                            setSearchText(text);
                        }
                    }}
                    // filter={{
                    //     dates: {
                    //         startDate: dates?.startDate, endDate: dates?.endDate,
                    //     },
                    //     status: {
                    //         value: '',
                    //         statusList: [{ label: "Completed", value: 'completed' }, { label: "Cancelled", value: "cancelled" }],
                    //     },
                    //     onDone(filters) {

                    //     },
                    // }}
                    add={{
                        onAddClick() {
                            setAddDialog(true);
                        }
                    }} />
            </div>
            <Add isShow={addDialog} closeDialog={() => setAddDialog(false)} />
            <RenderDocs id={selectedDriver?.id || ''} column={selectedDriver?.column || ''} onReset={() => {
                if (selectedDriver) setSelectedDriver(null)
            }} />
        </div>
    )
}

const RenderDocs: React.FC<DriverSelected> = ({ id, column, onReset }) => {
    if (id && column) {
        switch (column) {
            case "aadhaar":
                return <Aadhaar isShow={true} driver_id={id} onClose={onReset} />
            case "pan":
                return <Pan isShow={true} driver_id={id}  onClose={onReset} />
            case "dl":
                return <DL isShow={true} driver_id={id}  onClose={onReset} />
            case "rc":
                return <CarRC isShow={true} driver_id={id}  onClose={onReset} />
            case "profile_picture":
                return <ProfilePicture isShow={true} driver_id={id}  onClose={onReset} />
            case "bank":
                return <Bank isShow={true} driver_id={id}  onClose={onReset} />
            default:
                return null
        }
    } else {
        return null
    }

}

export default Drivers