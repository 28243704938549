
import styles from './App.module.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Header from './components/header/Header';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Rides from './pages/rides/Rides';
import Drivers from './pages/drivers/Drivers';
const queryClient = new QueryClient();

const getAdminToken = (): string | null => {
  return localStorage.getItem("token");
};


function App() {

  const Layout = () => {
    return (
      <div className={styles.root}>
        <div className={styles.main}>
          <header className={styles.header}>
            <Header />
          </header>
          <aside className={styles.sidebar}>
            <Sidebar />
          </aside>
          <main className={styles.content}>
            <QueryClientProvider client={queryClient}>
              <Outlet />
            </QueryClientProvider>
          </main>
        </div>
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={getAdminToken() ? <Layout /> : <Navigate to={'/login'} replace={true} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/rides" element={<Rides />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/users" element={<Rides />} />
          <Route path="/payments" element={<Rides />} />
          <Route path="/executives" element={<Rides />} />
          <Route path="/analytics" element={<Rides />} />
          <Route path="/settings" element={<Rides />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
