import React, { useEffect, useState } from 'react'
import styles from './Rides.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import RideList from './list/RideList';
import Detail from './detail/RideDetail';
import RideDetail from './detail/RideDetail';
import DynamicTable from '../../components/dynamictable/DynamicTable';

const rides = [
    { id: 1, customer: "Pangkam Damang Sangma", pick_up_location: "fgdgf", drop_location: "dgdffdgd", status: "Pending", date: "12/11/2023", time: "10:30 am" },
    { id: 2, customer: "Kaushal Bey", pick_up_location: "sgsdgdgdfgfdgdg", drop_location: "Completed", status: "Completed", date: "12/11/2023", time: "10:30 am" },
    { id: 3, customer: "Keduolie Mere", pick_up_location: "dgdfggdfg", drop_location: "Ongoing", status: "Ongoing", date: "13/11/2023", time: "10:30 am" },
    { id: 4, customer: "Anen Longchar", pick_up_location: "dgfdgd", drop_location: "sfsfsfs", status: "Cancelled", date: "14/11/2023", time: "10:30 am" },
    { id: 5, customer: "Pang Damang", pick_up_location: "sgsdgdgdfggdg", drop_location: "hsgsg", status: "fgfgd", date: "15/11/2023", time: "10:30 am" },
    { id: 6, customer: "fgdfgfdgf", pick_up_location: "gdgdfgf", drop_location: "dfggdf", status: "sgfdfgfg", date: "15/11/2023", time: "10:30 am" },
    { id: 7, customer: "abfgfdgfdc", pick_up_location: "gdgdfgf", drop_location: "dfggdf", status: "sgfdfgfg", date: "15/11/2023", time: "10:30 am" },
    { id: 8, customer: "fdgdgfgdfg", pick_up_location: "gdgdfgf", drop_location: "dfggdf", status: "sgfdfgfg", date: "15/11/2023", time: "10:30 am" },
    { id: 9, customer: "abfdgfgfdc", pick_up_location: "gdgdfgf", drop_location: "dfggdf", status: "sgfdfgfg", date: "15/11/2023", time: "10:30 am" },
    { id: 10, customer: "abdfgfgfdgc", pick_up_location: "gdgdfgf", drop_location: "dfggdf", status: "sgfdfgfg", date: "15/11/2023", time: "10:30 am" },

]


interface Column {
    label: string;
    name: string;
    hasBackground?: string;
    isClickable?: boolean
}

interface Row {
    id: string
    customer: string;
    driver: string;
    date: string;
    pickup_location: string;
    drop_location: string;
    vehicle_name: string;
    vehicle_number: string;
    status: string
}

const columns: Column[] = [
    { label: "Customer", name: "customer", isClickable: true },
    { label: "Driver", name: "driver", isClickable: true },
    { label: "Date", name: "date" },
    { label: "Status", name: "status", isClickable: true, hasBackground: 'status' },
    { label: "Pick-up Location", name: "pickup_location" },
    { label: "Drop Location", name: "drop_location" },
    { label: "Vehicle", name: "vehicle_name", isClickable: true },
    { label: "Vahicle Number", name: "vehicle_number", isClickable: true },
];

const generateRandomData = (): Row[] => {
    const customers = ['Alice Johnson', 'Bob Smith', 'Cathy Lee', 'David Brown', 'Emily White'];
    const drivers = ['Alice Johnson', 'Bob Smith', 'Cathy Lee', 'David Brown', 'Emily White'];
    const vehicleNames = ['Toyota Camry', 'Honda Accord', 'Ford Focus', 'Chevrolet Malibu', 'Nissan Altima'];
    const statuses = ['Completed', 'In Progress', 'Cancelled'];

    const getRandomDateTime = () => {
        const start = new Date(2023, 0, 1);
        const end = new Date();
        const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

        const day = date.getDate();
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;

        return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
    };

    return Array.from({ length: 100 }, (_, index) => ({
        id: (index + 1).toString(),
        customer: customers[Math.floor(Math.random() * customers.length)],
        driver: drivers[Math.floor(Math.random() * customers.length)],
        date: getRandomDateTime(),
        status: statuses[Math.floor(Math.random() * statuses.length)],
        pickup_location: `${Math.floor(Math.random() * 1000)} Random St, Springfield`,
        drop_location: `${Math.floor(Math.random() * 1000)} Another St, Springfield`,
        vehicle_name: vehicleNames[Math.floor(Math.random() * vehicleNames.length)],
        vehicle_number: `${String.fromCharCode(65 + Math.floor(Math.random() * 26))}${Math.floor(Math.random() * 10000)}`,
    }));
};



function Rides() {
    const [data, setData] = useState<Row[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [dates, setDates] = useState<{ startDate?: Date, endDate?: Date } | null>(null);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        const randomData = generateRandomData();
        setData(randomData);
    }, []);

    return (
        <div className={styles.container}>
            <DynamicTable
                title={'Rides'}
                columns={columns}
                rows={data}
                page={{
                    page: page,
                    totalPage: 10,
                    onPageChange(page) {
                        setPage(page);
                    }
                }}
                search={{
                    text: searchText,
                    onSearchTextChange(text) {
                        setSearchText(text);
                    }
                }}
                filter={{
                    dates: {
                        startDate: dates?.startDate, endDate: dates?.endDate,
                    },
                    status: {
                        value: '',
                        statusList: [{ label: "Completed", value: 'completed' }, { label: "Cancelled", value: "cancelled" },{ label: "Pending", value: "pending" }],
                    },
                    onDone(filters) {

                    },
                }}
            />
        </div>
    )
}

export default Rides