import styles from './DynamicTable.module.css'
import DynamicButton from '../dynamicbuton/DynamicButton'
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faBarsStaggered, faCheck, faChevronLeft, faChevronRight, faL, faMagnifyingGlass, faMinus, faPlus, faRotateRight, faSquareUpRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { ChangeEvent, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from '../datepicker/DatePicker'

type DateType = {
    startDate?: Date,
    endDate?: Date
}

type Filter = {
    dates?: DateType,
    status?: string
}

type Select = {
    id: string,
    column: string
}


interface DynamicTableProps {
    title?: string,
    columns: any[],
    rows: any[],
    onItemClick?: (data: Select) => void,
    page?: {
        page: number,
        totalPage: number,
        onPageChange: (page: number) => void,
    }
    add?: {
        onAddClick: () => void
    }
    search?: {
        text: string,
        onSearchTextChange: (e: string) => void
    }
    filter?: {
        dates?: {
            startDate?: Date,
            endDate?: Date,
        },
        status?: {
            value: string,
            statusList: any[],
        },
        onDone: (filters: Filter) => void,
    }

}


const DynamicTable = ({ title, columns, rows, onItemClick, page, add, search, filter }: DynamicTableProps) => {
    const [isSearchActive, setSearchActive] = useState<boolean>(false);
    const [isFilterShow, setShowFilter] = useState<boolean>(false);
    const [isDatePickerClicked, setDatePickerClicked] = useState<boolean>(false);
    const [filters, setFilters] = useState<Filter | null>(null);

    useEffect(() => {
        if (filter) {
            if (filter.dates) {
                setFilters((prevData) => ({
                    ...prevData,
                    dates: filter.dates
                }))
            }
            if (filter.status) {
                setFilters((prevData) => ({
                    ...prevData,
                    status: filter.status?.value
                }))
            }
        }
    }, []);



    const handleItemClick = (data: Select, isClick: boolean) => {
        if (!isClick) return
        if (!onItemClick) return
        onItemClick(data);
    }

    const handlePageChange = (operation: "minus" | "plus") => {
        if (page) {
            const newPage = operation === 'minus' ? (page.page - 1) : (page.page + 1);
            if (newPage >= 1 && newPage <= page.totalPage) {
                page.onPageChange(newPage);
            }
        }
    };


    const getDates = () => {
        if (filters?.dates?.startDate) {
            const startDay = String(filters.dates.startDate.getDate()).padStart(2, '0');
            const startMonth = String(filters.dates.startDate.getMonth() + 1).padStart(2, '0');
            const startYear = filters.dates.startDate.getFullYear();
            if (filters.dates.endDate) {
                const endDay = String(filters.dates.endDate.getDate()).padStart(2, '0');
                const endMonth = String(filters.dates.endDate.getMonth() + 1).padStart(2, '0');
                const endYear = filters.dates.endDate.getFullYear();
                return `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`
            } else {
                return `${startDay}/${startMonth}/${startYear}`
            }
        }
        return "Select date"
    }

    return (
        <div className={styles.container}>
            {
                title &&
                <h3 className={styles.title}>{title}</h3>
            }
            <div className={styles.menus}>
                <div className={styles.leftMenus}>
                    {
                        search &&
                        <div className={`${styles.searchContainer} ${isSearchActive ? styles.searchActivated : ''}`}>
                            <DynamicButton icon={faMagnifyingGlass} label={!isSearchActive ? 'Search' : ''} isHaveBackground={false} isRounded={true} onClick={() => setSearchActive(true)} />
                            <input type="text" value={search.text} placeholder='Search here...' onChange={(e) => search.onSearchTextChange(e.target.value)} />
                        </div>
                    }
                    {
                        search && isSearchActive &&
                        <DynamicButton
                            icon={faXmark}
                            isHaveBackground={false}
                            isRounded={false}
                            onClick={() => {
                                setSearchActive(false);
                                search.onSearchTextChange('');
                            }} />
                    }
                    {
                        filter &&
                        <div className={`${styles.filterBtnContainer} ${isFilterShow ? styles.showFilter : ''}`}>
                            <DynamicButton icon={faBarsStaggered} label='Filter' isHaveBackground={false} isRounded={false} onClick={() => setShowFilter(!isFilterShow)} />
                            <div className={`${styles.filter} `}>
                                {
                                    filter.dates &&
                                    <div className={styles.calenderContainer}>
                                        <label htmlFor="">Date: </label>
                                        <button onClick={() => setDatePickerClicked(true)}>{`${getDates()}`}</button>
                                        {
                                            isDatePickerClicked &&
                                            <DatePicker
                                                dates={{ startDate: filters?.dates?.startDate, endDate: filters?.dates?.endDate }}
                                                onDone={(dates) => {
                                                    setDatePickerClicked(false);
                                                    setFilters((prevData) => ({
                                                        ...prevData,
                                                        dates: dates
                                                    }));
                                                }} />
                                        }
                                    </div>
                                }

                                {
                                    filter.status &&
                                    <div className={styles.statusContainer}>
                                        <label htmlFor="status">Status:</label>
                                        <select name="value" id="status" value={filters?.status || ''} onChange={(e) =>
                                            setFilters((prevData) => ({
                                                ...prevData,
                                                status: e.target.value
                                            }))
                                        }>
                                            <option value="">--Select Status---</option>
                                            {
                                                filter.status.statusList.map((stat, index) => (
                                                    <option key={index} value={stat.value}>{stat.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                                <div className={styles.buttons}>
                                    <DynamicButton icon={faRotateRight} label='reset' isHaveBackground={false} isRounded={false} onClick={() => setFilters(null)} />
                                    <DynamicButton icon={faCheck} label='save' isHaveBackground={false} isRounded={false} onClick={() => setShowFilter(false)} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    add &&
                    <div className={styles.addBtnContainer}>
                        <DynamicButton icon={faPlus} label='Add new' isHaveBackground={false} isRounded={true} onClick={add.onAddClick} />
                    </div>

                }

            </div>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            {
                                columns.map((column, index) => (
                                    <th key={index}>
                                        <p className={styles.headValue}>{column.label}</p>
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((item) => (
                            <tr key={item.id}>
                                {
                                    columns.map((column, index) => (
                                        <td key={index}>
                                            {
                                                column.name === "profile_picture" ? <RenderProfilePicture value={item[column.name]} onClick={() => handleItemClick({ id: item.id, column: column.name }, column.isClickable)} />
                                                    :
                                                    <p onClick={() => handleItemClick({ id: item.id, column: column.name }, column.isClickable)} className={`${styles.bodyValue} ${column.isClickable ? styles.clickableBody : ''} ${column.hasBackground ? styles.bodyValueHasBackground : ''}`} style={getBackgroundColor(column.hasBackground, item[column.name])}>
                                                        {item[column.name] || 'Add new'}
                                                    </p>
                                            }

                                        </td>
                                    ))
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {
                page &&
                <div className={styles.pagination}>
                    <p>Page {page.page} of {page.totalPage}</p>
                    <div className={styles.paginationBtns}>
                        <button onClick={() => handlePageChange('minus')}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>

                        <button onClick={() => handlePageChange('plus')}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>

            }
        </div>
    )
}

const getBackgroundColor = (type: string, value: string): React.CSSProperties => {
    let style: React.CSSProperties = {};

    if (['status'].includes(type)) {
        style = {
            width: "max-content",
            padding: "5px 12px",
            borderRadius: "45px",
            textAlign: "center",
        };

        switch (value) {
            case "Completed":
                style.backgroundColor = "green";
                style.color = "white";
                break;
            case "Cancelled":
                style.backgroundColor = "lightcoral";
                style.color = "black";
                break;
            case "In Progress":
                style.backgroundColor = "lightblue";
                break;
            default:
                style.backgroundColor = "white";
        }
    }

    return style;
};

const RenderProfilePicture: React.FC<{ value: string; onClick: () => void }> = ({ value, onClick }) => {
    return value ? (
        <img
            className={styles.profile_picture}
            src={value}
            alt="Profile"
            onClick={onClick}
        />
    ) : (
        <p className={`${styles.bodyValue} ${styles.clickableBody}`} onClick={onClick}>Add new</p>
    );
};



export default DynamicTable