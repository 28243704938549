import React, { ChangeEvent, useState } from 'react'
import styles from './Login.module.css'
import { log } from 'console';

type Login = {
    role?: string;
    username?: string;
    password?: string;
}

function Login() {
    const [loginData, setLoginData] = useState<Login>()

    const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setLoginData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(loginData);
        localStorage.setItem('token', 'pangkamdamangsangma');
        window.location.replace('/');
    };

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <div style={{ flex: 1 }}></div>
                <div>
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit} action="" className={styles.form}>
                        <div>
                            <label htmlFor="">Role</label>
                            <select name='role' value={loginData?.role} onChange={onInputChange}>
                                <option value="">--Select role---</option>
                                <option value="CEO">Chief Executive Officer</option>
                                <option value="COO">Chief Operating Officer</option>
                                <option value="CTO">Chief Techinal Oficer</option>
                                <option value="JCTO">Joint Chief Techinal Officer</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="">Username</label>
                            <input type="text" placeholder='enter username' name='username' value={loginData?.username} onChange={onInputChange} />
                        </div>
                        <div>
                            <label htmlFor="">Password</label>
                            <input type="text" placeholder='enter password' name='password' value={loginData?.password} onChange={onInputChange} />
                        </div>
                        <button type='submit' className={styles.submitBtn}>Login</button>
                    </form>
                </div>
                <div style={{ flex: 1 }}></div>
            </div>
        </div>
    )
}

export default Login