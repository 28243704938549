import React from 'react'
import styles from './Dashboard.module.css'

function Dashboard() {
    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>Dashboard</h2>
            <div className={styles.firstSection}>
                <div className={styles.firstSectionItem}>
                    <div>
                        <p>Rides</p>
                        <img src="/car_ride.png" alt="" />
                    </div>
                    <div>
                        <div>
                            <p>Total</p>
                            <p>1000</p>
                        </div>
                        <div>
                            <p>Completed</p>
                            <p>900</p>
                        </div>
                        <div>
                            <p>Cancelled</p>
                            <p>100</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard