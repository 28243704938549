import React, { useEffect, useState } from 'react';
import styles from './DatePicker.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import DynamicButton from '../dynamicbuton/DynamicButton';

type DateType = {
    startDate?: Date,
    endDate?: Date
}

interface DatePickerProps {
    dates: {
        startDate?: Date,
        endDate?: Date
    }
    onDone: (dates: DateType) => void
}

const DatePicker = ({ dates, onDone }: DatePickerProps) => {
    const [startDate, setStartDate] = useState<Date | undefined>(dates.startDate || undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(dates.endDate || undefined);
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());

    const onResetPick = () => {
        setStartDate(undefined);
        setEndDate(undefined);
    }

    const handleDateChange = (day: number) => {
        const selectedDate = new Date(year, month, day);
        if (!startDate || (startDate && endDate)) {
            setStartDate(selectedDate);
            setEndDate(undefined);
        } else if (selectedDate <= startDate) {
            setStartDate(selectedDate);
            setEndDate(undefined);
        } else {
            setEndDate(selectedDate);
        }
    };

    const renderDays = (month: number, year: number) => {
        const days = [];
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        for (let i = 0; i < firstDay.getDay(); i++) {
            days.push(<div key={`empty-${i}`} className={`${styles.day} ${styles.empty}`} />);
        }

        for (let day = 1; day <= lastDay.getDate(); day++) {
            const currentDate = new Date(year, month, day);
            const isInRange = startDate && endDate && currentDate > startDate && currentDate < endDate;
            const isStartDate = startDate && currentDate.toDateString() === startDate.toDateString();
            const isEndDate = endDate && currentDate.toDateString() === endDate.toDateString();

            days.push(
                <div
                    key={day}
                    className={`${styles.day} ${isInRange ? styles.in_range : ''} ${isStartDate ? styles.start : ''} ${isEndDate ? styles.end : ''}`}
                    onClick={() => handleDateChange(day)}
                >
                    {day}
                </div>
            );
        }

        return days;
    };
    const changeYear = (offset: number) => {
        setYear((prevYear) => prevYear + offset);
    };

    const changeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const month = Number(event.target.value);
        setMonth(month)
    };

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const Calendar = () => {
        return (
            <div className={styles.calender}>
                <h3>Select Date</h3>
                <div className={styles.month_year_selector}>
                    <select value={month} onChange={(e) => changeMonth(e)}>
                        {months.map((month, index) => (
                            <option key={index} value={index}>{month}</option>
                        ))}
                    </select>
                    <button onClick={() => changeYear(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>{year}</span>
                    <button onClick={() => changeYear(1)}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </div>
                <div className={styles.days}>
                    {renderDays(month, year)}
                </div>
                <div className={styles.buttons}>
                    <DynamicButton label='Reset' isHaveBackground={false} isRounded={false} onClick={onResetPick} />
                    <DynamicButton label='Save' isHaveBackground={false} isRounded={false} onClick={() => onDone({ startDate, endDate })} />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.calenders}>
            <Calendar />
        </div>
    );
};

export default DatePicker;
