import React from 'react'
import styles from './Header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

function Header() {

    const handleLogoutClick = ()=>{
        localStorage.removeItem('token');
        window.location.replace('/');
    }
    return (
        <div className={styles.container}>
            <h2>UraCab Admin</h2>
            <button className={styles.logoutBtn} onClick={handleLogoutClick}>
                <FontAwesomeIcon icon={faRightFromBracket} />
                Logout
            </button>
        </div>
    )
}

export default Header