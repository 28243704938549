import React, { ChangeEvent, useState } from 'react'
import DynamicForm from '../../../components/dynamicform/DynamicForm'
import styles from './Add.module.css'
import DialogContainer from '../../../components/dialogcontainer/DialogContainer'
import StandardInput from '../../../components/inputs/standardInput/StandardInput'
import SelectInput from '../../../components/inputs/selectInput/SelectInput'
import DynamicButton from '../../../components/dynamicbuton/DynamicButton'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

interface AddProps {
    isShow: boolean,
    closeDialog: () => void
}

type DriverDetail = {
    firstname?: string;
    middlename?: string;
    lastname?: string;
    gender?: string;
    dob?: string;
    phone?: string;
    email?: string;
    picture?: {
        preview: string;
        file: File | null;
    }
}

const Add = ({ isShow, closeDialog }: AddProps) => {
    const [formData, setFormData] = useState<DriverDetail>();
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;

        if (name === 'phone' && !/^\d*$/.test(value)) return;
        if (type === 'file') {
            const target = e.target as HTMLInputElement;
            const files = target.files;

            if (files && files.length > 0) {
                const file = files[0];
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData((prevData) => ({
                        ...prevData,
                        picture: {
                            preview: reader.result as string,
                            file: file
                        },
                    }));
                };
                reader.readAsDataURL(file);
            }
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const removeImage = () => {
        setFormData((prevData) => ({
            ...prevData,
            picture: {
                preview: '',
                file: null
            }
        }));
    };
    return isShow ? (
        <DialogContainer>
            <div className={styles.container}>
                <div className={styles.heading}>
                    <h3>Driver Registration</h3>
                    <DynamicButton icon={faXmark} isHaveBackground={true} isRounded={true} onClick={closeDialog} />
                </div>
                <form action="" className={styles.form}>
                    <div className={styles.formSection}>
                        <h4>Profile</h4>
                        <div className={styles.inputContainer}>
                            <StandardInput id={'firstname'} label={'First Name'} name={'firstname'} value={formData?.firstname || ''} inputType={'text'} placeHolder={'enter firstname'} onChange={handleInputChange} />
                            <StandardInput id={'middlename'} label={'Middle Name'} name={'middlename'} value={formData?.middlename || ''} inputType={'text'} placeHolder={'enter middlename'} onChange={handleInputChange} />
                            <StandardInput id={'lastname'} label={'Last Name'} name={'lastname'} value={formData?.lastname || ''} inputType={'text'} placeHolder={'enter lastname'} onChange={handleInputChange} />
                            <SelectInput id={'gender'} label={'Gender'} name={'gender'} value={formData?.gender || ''} options={[{ label: "--Select Gender--", value: "" }, { label: "Male", value: "male" }, { label: "Female", value: "female" }, { label: "Others", value: "others" }]} onChange={handleInputChange} />
                            <StandardInput id={'dob'} label={'Date of birth'} name={'dob'} value={formData?.dob || ''} inputType={'date'} onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className={styles.formSection}>
                        <h4>Contacts</h4>
                        <div className={styles.inputContainer}>
                            <StandardInput id={'phone'} label={'Contact Number'} name={'phone'} value={formData?.phone || ''} inputType={'text'} placeHolder={'enter phone number'} maxLenght={10} onChange={handleInputChange} />
                            <StandardInput id={'email'} label={'Email Address'} name={'email'} value={formData?.email || ''} inputType={'email'} placeHolder={'enter email address'} onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className={styles.formSection}>
                        <h4>Passport photo</h4>
                        <div className={styles.passportContainer}>
                            {
                                !formData?.picture?.file ?
                                    <>
                                        <label className={styles.addImageBtn} htmlFor="picture">Pick Image</label>
                                        <input type="file" accept='image/*' id='picture' name='picture' style={{ display: 'none' }} onChange={handleInputChange} />
                                    </>
                                    :
                                    <div>
                                        <p>{formData?.picture?.file?.name}</p>
                                        <DynamicButton label='remove' isHaveBackground={false} isRounded={false} onClick={removeImage} />
                                    </div>
                            }
                        </div>

                    </div>
                    {
                        formData?.picture?.preview &&
                        <img className={styles.previewImage} src={formData?.picture.preview} alt="" />
                    }
                    <DynamicButton buttonType={'submit'} label='Register' isHaveBackground={true} isRounded={false} />
                </form>
            </div>
        </DialogContainer>
    ) : null
}

export default Add